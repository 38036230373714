<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="iconConfig"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
          <div :style="{ display: 'flex' }">
            <a-form-model-item :label="`${ penetrate === 2 && (currentIndex === pageIconLength - 1) ? '我的(小程序)' : `底部导航${currentIndex + 1}` }`" prop="checkedIconUrl">
              <div :style="{ display: 'flex' }">
                <div class="clearfix">
                  <a-upload
                    :disabled="disabled"
                    :action="IMG_API + '/oss/files'"
                    list-type="picture-card"
                    :file-list="checkedIconFileList"
                    @preview="handlePreview"
                    @change="handleCheckedChange"
                  >
                    <div v-if="checkedIconFileList.length < 1">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        {{ `上传/${ penetrate === 1 && pageIconLength >= 3 && currentIndex === 1 ? 'icon图' : '选中态'}` }}
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item prop="defaultIconUrl">
              <div :style="{ display: 'flex' }">
                <div class="clearfix">
                  <a-upload
                    :disabled="disabled"
                    :action="IMG_API + '/oss/files'"
                    list-type="picture-card"
                    :file-list="defaultIconFileList"
                    @preview="handlePreview"
                    @change="handleDefaultChange"
                  >
                    <div v-if="defaultIconFileList.length < 1">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        {{ `上传/${ penetrate === 1 && pageIconLength >= 3 && currentIndex === 1 ? '背景图' : '默认态'}` }}
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </div>
              </div>
            </a-form-model-item>
          </div>
    </a-form-model>
  </div>
</template>

<script>
import { IMG_API } from "../../../../config";

export default {
  computed: {
    IMG_API() {
      return IMG_API
    }
  },
  props: ['iconConfig', 'currentIndex', 'pageIconLength', 'penetrate', 'disabled'],
  watch: {
    'iconConfig.checkedIconUrl': {
        handler(val) {
          if(val) {
            this.checkedIconFileList = [{
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: this.iconConfig.checkedIconUrl
            }]
          } else {
            this.checkedIconFileList = []
          }
        },
      immediate: true,
      deep: true
    },
    'iconConfig.defaultIconUrl': {
      handler(val) {
        if(val) {
          this.defaultIconFileList = [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: this.iconConfig.defaultIconUrl
          }]
        } else {
          this.defaultIconFileList = []
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      checkedIconFileList: [],
      defaultIconFileList: [],
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      // 预览的图片
      previewImage: '',
      // 是否预览
      previewVisible: false,
      rules: {
        checkedIconUrl: [
          { required: true, message: '请上传选中态icon', trigger: 'blur' }
        ],
        defaultIconUrl: [
          { required: true, message: '请上传默认态icon', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    /**
     * 保存
     */
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if(valid) {
          this.$emit('submitVisible', true)
        } else {
          this.$emit('submitVisible', false)
        }
      })
    },
    /**
     * 预览
     */
    async handlePreview(file) {
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    /**
     * 取消预览
     */
    handleCancel() {
      this.previewVisible = false
    },
    /**
     * 选中态
     */
    handleCheckedChange({ fileList }) {
      this.checkedIconFileList = fileList;
      if(fileList && fileList.length && fileList[0].status === 'done') {
        this.iconConfig.checkedIconUrl = fileList[0].response.redirect_uri
      } else {
        this.iconConfig.checkedIconUrl = ''
      }
    },
    /**
     * 默认状态
     */
    handleDefaultChange({ fileList }) {
      this.defaultIconFileList = fileList;
      if(fileList && fileList.length && fileList[0].status === 'done') {
        this.iconConfig.defaultIconUrl = fileList[0].response.redirect_uri
      } else {
        this.iconConfig.defaultIconUrl = ''
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
