<template>
  <div>
    <ds-header :title="`${operationType}主页面`"></ds-header>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="皮肤名称" prop="skinName">
            <a-input :disabled="disabled" class="indexClass" :maxLength="10" placeholder="请输入皮肤名称" v-model.trim="form.skinName" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="penetrate" label="透出端" prop="penetrate">
            <a-radio-group :disabled="disabled" @change="handlePenetrateChange" v-model="form.penetrate">
              <a-radio :value="1">
                App
              </a-radio>
              <a-radio :value="2">
                小程序
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="皮肤设为默认" prop="isDefault">
            <a-radio-group
              v-model="form.isDefault"
              :disabled="disabled"
              @change="handleIsDefaultChange"
            >
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item v-if="!form.isDefault" label="生效时间" prop="effectiveTime">
            <a-range-picker :disabled="disabled" show-time v-model="form.effectiveTime" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item ref="projectId" label="所属项目" prop="projectId">
            <a-tree-select
              :disabled="disabled"
              v-model="form.projectId"
              show-search
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择所属项目"
              allow-clear
              tree-default-expand-all
              @change="handleProjectChange"
            >
              <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projects" :key="item.id">
                <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
                </a-tree-select-node>
              </a-tree-select-node>
            </a-tree-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="页面主题" prop="homePageInfoId">
            <a-select
              placeholder="请选择页面主题"
              v-model="form.homePageInfoId"
              :disabled="disabled"
              @change="handleSelectHomePage"
            >
              <a-select-option v-for="item in pageInfo" :key="item.homePageId">
                {{ item.homePageName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div>
        <div class="page-class">
          <div class="page-title">
            顶部图标
          </div>
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="我的(APP)" prop="myIcon" :required="form.penetrate === 1">
                <div :style="{ display: 'flex' }">
                  <div class="clearfix">
                    <a-upload
                      :disabled="disabled"
                      :action="IMG_API + '/oss/files'"
                      list-type="picture-card"
                      :file-list="myIconFileList"
                      @preview="handlePreview"
                      @change="handleChange"
                    >
                      <div v-if="myIconFileList.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                          上传
                        </div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                  </div>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" :style="{ display: 'flex' }">
              <a-form-model-item label="常用功能" prop="commonIconSelected">
                <div :style="{ display: 'flex' }">
                  <div class="clearfix">
                    <a-upload
                      :disabled="disabled"
                      :action="IMG_API + '/oss/files'"
                      list-type="picture-card"
                      :file-list="commonIconSelectedFileList"
                      @preview="handlePreview"
                      @change="handleCommonIconSelectedChange"
                    >
                      <div v-if="commonIconSelectedFileList.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                          上传/选中态
                        </div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                  </div>
                </div>
              </a-form-model-item>
              <a-form-model-item prop="commonIconDefault">
                <div :style="{ display: 'flex' }">
                  <div class="clearfix">
                    <a-upload
                      :disabled="disabled"
                      :action="IMG_API + '/oss/files'"
                      list-type="picture-card"
                      :file-list="commonIconDefaultFileList"
                      @preview="handlePreview"
                      @change="handleCommonIconDefaultChange"
                    >
                      <div v-if="commonIconDefaultFileList.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                          上传/默认态
                        </div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                  </div>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div v-if="pageIconConfigList.length">
        <div class="page-class">
          <div class="page-title">
            底部图标
          </div>
            <div v-for="(item, index) in pageIconConfigList" :key="index" :style="{ paddingLeft: '228px' }">
              <BottomIcon
                ref="bottomIcon"
                :iconConfig="item"
                :currentIndex="index"
                :disabled="disabled"
                :pageIconLength="pageIconConfigList.length"
                :penetrate="form.penetrate"
                @submitVisible="submitVisible"
              />
            </div>
        </div>
      </div>
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="状态" :required="true">
            <a-radio-group :disabled="disabled" v-model="form.status">
              <a-radio :value="1">启用</a-radio>
              <a-radio :value="0">禁用</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" v-show="!disabled">
          保存
        </a-button>
        <a-button style="margin-left: 10px;" @click="cancel">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {getProjects} from "@/api/common";
import {addSkin, getPageInfoByProjectId, infomationSkin, updateSkin} from '@/api/skinManage'
import BottomIcon from "../components/BottomIcon.vue";
import {IMG_API} from "@/config";
import moment from "moment";
export default {
  name: 'AddSkin',
  computed: {
    IMG_API() {
      return IMG_API
    }
  },
  // watch: {
  //   'form.homePageInfoId': {
  //     handler(val) {
  //       this.pageIconConfigList = []
  //       if(val) {
  //         const num = this.pageInfo.find(item => item.homePageId === val).homePageSize
  //         for(let i = 0; i < num; i++) {
  //           const pageConfig = {
  //             checkedIconUrl: '',
  //             defaultIconUrl: ''
  //           }
  //           this.pageIconConfigList.push(pageConfig)
  //         }
  //       }
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  components: {BottomIcon},
  data() {
    let checkMyIcon = (rule, value, callback) => {
      if (!value && this.form.penetrate === 1) {
        callback(new Error('请上传我的Icon'));
      } else {
        callback();
      }
    };
    return {
      // 子组件的校验结果
      flagList: [],
      // 页面icon配置
      pageIconConfigList:[],
      // 常用功能选中状态icon
      commonIconSelectedFileList: [],
      // 常用功能默认状态icon
      commonIconDefaultFileList: [],
      // 预览的图片
      previewImage: '',
      // 我的icon
      myIconFileList: [],
      // 预览
      previewVisible: false,
      // 所有项目
      projects: [],
      // 操作类型
      operationType: '',
      disabled: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      // 选中的页面主题信息
      pageInfo: {},
      form: {
        // 皮肤名称
        skinName: '',
        projectId: undefined,
        // 透出端（1：APP 2：小程序）
        penetrate: 1,
        // 主页面配置ID
        homePageInfoId: undefined,
        // 我的APP icon
        myIcon: '',
        // 常用功能默认态icon
        commonIconDefault: '',
        // 常用功能选中态icon
        commonIconSelected: '',
        // 状态 (0: 禁用，1：启用)
        status: 0,
        // 是否默认（0：否，1：是）
        isDefault: 0,
        // 生效时间段
        effectiveTime: undefined,
        // 生效开始时间
        effectiveStartTime: '',
        // 生效结束时间
        effectiveEndTime: '',
      },
      rules: {
        skinName: [{ required: true, message: "请输入皮肤名称", trigger: "blur" }],
        penetrate: [{ required: true, message: '请选择透出端', trigger: 'change' }],
        effectiveTime: [{ required: true, message: '请选择有效时间', trigger: 'change' }],
        isDefault: [{ required: true, message: '请选择是否默认', trigger: 'change' }],
        projectId: [{ required: true, message: '请选择所属项目', trigger: 'change' }],
        homePageInfoId: [{ required: true, message: '请选择页面主题', trigger: 'change' }],
        myIcon: [{ validator: checkMyIcon, trigger: 'change' }],
        commonIconSelected: [{ required: true, message: '请上传常用功能选中态icon', trigger: 'change' }],
        commonIconDefault: [{ required: true, message: '请上传常用功能默认态icon', trigger: 'change' }],
      }
    }
  },
  async created() {
    const type = this.$route.query.type;
    const id = this.$route.query.id
    if(type === 'view') {
      this.disabled = true
      this.operationType = '查看'
      await this.getDetail(id);
    } else if (type === 'edit') {
      this.operationType = '编辑'
      await this.getDetail(id)
    } else {
      this.operationType = '新增'
    }
    // 查询项目
    await this.getProjectList()
    // 查询原声页面
    // await this.getNativePage()
  },
  methods: {
    /**
     * 是否为默认皮肤change事件
     */
    handleIsDefaultChange(e) {
      const val = e.target.value;
      if(val) {
        this.form.effectiveTime = undefined
      }
    },
    /**
     * 页面主题change事件
     */
    handleSelectHomePage(e) {
      this.form.myIcon = ''
      this.myIconFileList = []
      this.form.commonIconDefault = ''
      this.form.commonIconSelected = ''
      this.commonIconSelectedFileList = []
      this.commonIconDefaultFileList = []
      this.pageIconConfigList = []
      if(e) {
        const num = this.pageInfo.find(item => item.homePageId === e).homePageSize
        for(let i = 0; i < num; i++) {
          const pageConfig = {
            checkedIconUrl: '',
            defaultIconUrl: ''
          }
          this.pageIconConfigList.push(pageConfig)
        }
      }
    },
    /**
     * 透出端change事件
     */
    handlePenetrateChange() {
      this.form.projectId = undefined
      this.form.homePageInfoId = undefined
      this.form.myIcon = ''
      this.myIconFileList = []
      this.form.commonIconDefault = ''
      this.form.commonIconSelected = ''
      this.commonIconSelectedFileList = []
      this.commonIconDefaultFileList = []
      this.pageIconConfigList = []
    },
    /**
     * 查询详情
     */
    async getDetail(id) {
      const params = { id }
      const res = await infomationSkin(params)
      const { code, data, msg } = res
      if (code === '200') {
        let newData = JSON.parse(JSON.stringify(data))
        // 处理有效期
        if(newData.effectiveStartTime && newData.effectiveEndTime) {
          newData.effectiveTime = [newData.effectiveStartTime, newData.effectiveEndTime]
        }
        // 我的icon
        if(newData.myIcon) {
          this.myIconFileList = [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: newData.myIcon
          }]
        }
        // 常用功能
        if(newData.commonIconSelected && newData.commonIconDefault) {
          this.commonIconSelectedFileList = [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: newData.commonIconSelected
          }]
          this.commonIconDefaultFileList = [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: newData.commonIconDefault
          }]
        }
        // 底部icon
        this.pageIconConfigList = newData.skinDetailList
        // 去查询页面主题
        await this.getPageInfoByProjectId(newData.projectId, data.penetrate)
        this.form = newData

      } else {
        this.$message.warning(msg)
      }
    },
    /**
     * 查询有子项目的项目列表
     */
    async getProjectList() {
      const res = await getProjects()
      const { code, data, msg } = res
      if(code === '200') {
        this.projects = data && data.length && data.map(item => {
          return {
            ...item,
            disabled: !(item.child === null)
          }
        })
      } else {
        this.$message.error(msg)
      }
    },
    /**
     * 所属项目change事件
     */
    handleProjectChange(val) {
      let penetrate = ''
      if (!this.form.penetrate || this.form.penetrate.length === 0 ) {
        this.$message.warning('请先选择透出端')
        this.form.projectId = undefined
        return
      }
      this.pageInfo = []
      this.form.homePageInfoId = undefined
      this.getPageInfoByProjectId(val, this.form.penetrate)
    },
    /**
     * 根据项目id，查询页面主题
     */
    async getPageInfoByProjectId(id, penetrate) {
      const res = await getPageInfoByProjectId(id, penetrate)
      const { code, data, msg } = res
      if(code === '200') {
        if(data && data.length) {
          this.pageInfo = data
        } else {
          this.$message.warning('暂未查询到关联的页面主题！')
        }
        this.pageInfo = data
      } else {
        this.$message.error(msg)
      }
    },
    /**
     * 我的-icon
     */
    handleChange({ fileList }) {
      this.myIconFileList = fileList;
      if(fileList && fileList.length && fileList[0].status === 'done') {
        this.form.myIcon = fileList[0].response.redirect_uri
      } else {
        this.form.myIcon = ''
      }
    },
    /**
     * 常用功能选中状态icon
     */
    handleCommonIconSelectedChange({ fileList }) {
      this.commonIconSelectedFileList = fileList;
      if(fileList && fileList.length && fileList[0].status === 'done') {
        this.form.commonIconSelected = fileList[0].response.redirect_uri
      } else {
        this.form.commonIconSelected = ''
      }
    },
    /**
     * 常用功能默认状态icon
     */
    handleCommonIconDefaultChange({ fileList }) {
      this.commonIconDefaultFileList = fileList;
      if(fileList && fileList.length && fileList[0].status === 'done') {
        this.form.commonIconDefault = fileList[0].response.redirect_uri
      } else {
        this.form.commonIconDefault = ''
      }
    },
    /**
     * 子组件的校验
     */
    submitVisible(flag) {
      this.flagList.push(flag)
    },
    /**
     * 预览
     */
    async handlePreview(file) {
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    /**
     * 取消预览
     */
    handleCancel() {
      this.previewVisible = false
    },
    /**
     * 取消
     */
    cancel() {
      this.$router.back()
    },
    /**
     * 保存
     */
    async onSubmit() {
      console.log(this.form, 'one')
      console.log(this.pageIconConfigList, 'one')
      const that = this
      // 初始化子组件的校验结果
      this.flagList = []
      const valid = await this.$refs.ruleForm.validate().catch(e => e)
      new Promise(function (resolve, reject) {
        console.log(that.$refs.bottomIcon, 'this.$refs.bottomIcon')
        if(that.$refs.bottomIcon && that.$refs.bottomIcon.length) {
          that.$refs.bottomIcon.forEach(async item => {
            await item.submit()
          })
        }
      })
      let res = {}
      const newForm = JSON.parse(JSON.stringify(this.form))
      // 处理有效期
      if (newForm.effectiveTime && newForm.effectiveTime.length) {
        newForm.effectiveStartTime = moment(newForm.effectiveTime[0]).format('YYYY-MM-DD HH:mm:ss')
        newForm.effectiveEndTime = moment(newForm.effectiveTime[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      let obj = {
        ...newForm,
        skinDetailList: this.pageIconConfigList.map((item, index) => {
          return {
            ...item,
            pageSort: index + 1
          }
        })
      }
      if(valid && this.flagList.every(item => item === true)) {
        if(this.$route.query.type === 'add') {
          res = await addSkin(obj)
        } else {
          res = await updateSkin(obj)
        }
        const { code, msg } = res
        if(code === '200') {
          this.$message.success('操作成功！')
          this.$router.back()
        } else {
          this.$message.warning(msg)
        }
      } else {
        return false;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.page-class {
  border: #9c9c9c solid 1px;
  margin-bottom: 20px;
  .page-title {
    background: #eeeeee;
    border-bottom:  #9c9c9c solid 1px;
    padding: 10px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000000;
  }
}
</style>
